import React, {Component} from 'react';
import './Navbar.css';

class Navbar extends Component {
	state = {  } 
	render() { 
		return (
			<nav className='nav'>
				<a href='/' className='site-title'>
					Clayton Adawag
				</a>
				<ul>
					<li>
						<a href='#home'>Home</a>
					</li>
					<li>
						<a href='#projects'>Projects</a>
					</li>
					<li>
						<a href='#activities'>Activities</a>
					</li>
					<li>
						<a href='#experiance'>Experience</a>
					</li>
					<li>
						<a href='#resume'>Resume</a>
					</li>
				</ul>
			</nav>
		);
	}
}
 
export default Navbar;