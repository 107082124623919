import React from 'react';

const ProfileImage = ({ src, alt, size = 100, shape = 'circle' }) => {
  const styles = {
    width: `${size}px`,
    height: `${size}px`,
    borderRadius: shape === 'circle' ? '50%' : '0%',
    objectFit: 'cover',
  };

  return <img src={src} alt={alt} style={styles} />;
};

export default ProfileImage;