import React, { Component } from 'react';
import './App.css';
import profileImage from './assets/profilephoto.jpeg';
import Navbar from './components/Navbar/Navbar';
import ProfileIcon from './components/ProfileIcon/ProfileIcon'

class App extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="App">
          <Navbar/>

          <section id='home' style={{backgroundColor: '#FFDAB9'}}>
            <div className='left-section'>
              <ProfileIcon
                src={profileImage}
                alt="User Profile" 
                size={250} 
                shape="circle"
              />
              <div>
                Clayton Adawag
              </div>
              <div>
                Costa Mesa, CA
              </div>
            </div>
            <div className='right-section'>
            <h2>About This Website</h2>
              <p>My name is Clayton Adawag. I am a graduate from the University of California, Santa Cruz with my BS in Computer Science.</p>
              <p>This website serves as a collection of my experiences and projects while also displaying my hobbies/interests. I hope that you will learn a little more about me, my passions, and skills as an aspiring software developer. </p>
              <p>This website is currently under construction and will be updated in due time. Please be patient and stay tune for new content. Thanks!</p>
            </div>
          </section>

          <section id='projects'>
            <div className='left-section'>
              <h2>Projects</h2>
            </div>
            <div className='right-section'>

            </div>
          </section>

          <section id='activities' style={{backgroundColor: '#FFDAB9'}}>
            <div className='left-section'>            
              <h2>Activities</h2>
            </div>
            <div className='right-section'>

            </div>
          </section>

          <section id='experiance'>
            <div className='left-section'>
              <h2>Experiance</h2>
            </div>
            <div className='right-section'>

            </div>
          </section>

          <section id='resume' style={{backgroundColor: '#FFDAB9'}}>
            <div className='left-section'>
              <h2>Resume</h2>
            </div>
            <div className='right-section'>

            </div>
          </section>
        </div>
      </React.Fragment>
    );
  }
}

export default App;
